/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/sigma@1.2.1/build/sigma.min.js
 * - /npm/sigma@1.2.1/build/plugins/sigma.exporters.svg.min.js
 * - /npm/sigma@1.2.1/build/plugins/sigma.layout.forceAtlas2.min.js
 * - /npm/sigma@1.2.1/build/plugins/sigma.layout.noverlap.min.js
 * - /npm/sigma@1.2.1/build/plugins/sigma.neo4j.cypher.min.js
 * - /npm/sigma@1.2.1/build/plugins/sigma.parsers.gexf.min.js
 * - /npm/sigma@1.2.1/build/plugins/sigma.parsers.json.min.js
 * - /npm/sigma@1.2.1/build/plugins/sigma.plugins.animate.min.js
 * - /npm/sigma@1.2.1/build/plugins/sigma.plugins.dragNodes.min.js
 * - /npm/sigma@1.2.1/build/plugins/sigma.pathfinding.astar.min.js
 * - /npm/sigma@1.2.1/build/plugins/sigma.plugins.filter.min.js
 * - /npm/sigma@1.2.1/build/plugins/sigma.plugins.neighborhoods.min.js
 * - /npm/sigma@1.2.1/build/plugins/sigma.plugins.relativeSize.min.js
 * - /npm/sigma@1.2.1/build/plugins/sigma.renderers.customEdgeShapes.min.js
 * - /npm/sigma@1.2.1/build/plugins/sigma.renderers.customShapes.min.js
 * - /npm/sigma@1.2.1/build/plugins/sigma.renderers.edgeDots.min.js
 * - /npm/sigma@1.2.1/build/plugins/sigma.renderers.edgeLabels.min.js
 * - /npm/sigma@1.2.1/build/plugins/sigma.renderers.parallelEdges.min.js
 * - /npm/sigma@1.2.1/build/plugins/sigma.renderers.snapshot.min.js
 * - /npm/sigma@1.2.1/build/plugins/sigma.statistics.HITS.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
